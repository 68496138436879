<template>
  <div>
    <!-- header -->
    <div style="margin: -45px -24px 0px">
      <a-card :bordered="false">
        <DescriptionList>
          <div style="text-align: right" class="table-operatorTitle">
            <label class="detailTop-edit">
              <span style="margin-right:10px">退租详情</span>
              <div :class="this.changeImgbg()">
                {{ showTenantStatus(this.tenantStatus) }}
              </div>
            </label>
          </div>
          <div style="margin-left: 54px;width: 90%">
            <Description term="门店名称">
              <label>{{ this.facilityName }}</label>
            </Description>
            <Description term="厨房品牌">
              <label>{{ showKitchenBrand(this.kitchenBrand) }}</label>
            </Description>
            <Description term="档口编号">
              <label>{{ this.kitchenCode }}</label>
            </Description>
            <Description term="店铺名称">
              <label>{{ this.storeName }}</label>
            </Description>
            <Description term="城市">
              <label>{{ this.cityName }}</label>
            </Description>
            <Description term="合同编号">
              <label>
                <a @click="contractDetail()" style="color:#009afe">
                  {{ this.contractCode }}
                </a>
              </label>
            </Description>
            <Description term="退租责任方">
              <label>{{ this.reasonLiabilityName }}</label>
            </Description>
            <Description term="退租原因">
              <label>{{ this.reasonCatName }}</label>
            </Description>
            <Description term="详细原因">
              <label>{{ this.reasonSubName }}</label>
            </Description>
            <Description term="备注">
              <label>{{ this.remark }}</label>
            </Description>
          </div>
        </DescriptionList>
      </a-card>
    </div>
    <!-- 退租信息、离场信息 -->
    <div style="margin-bottom: 24px; margin: -24px -24px 24px">
      <a-card
        style="width:100%"
        :bordered="false"
        :tabList="tabListNoTitle"
        :activeTabKey="noTitleKey"
        @tabChange="key => onTabChange(key, 'noTitleKey')"
      >
      </a-card>
    </div>
    <!-- 违约退租 -->
    <div v-if="this.rentOutType == 3">
      <a-card v-if="noTitleKey === 'baseDetail'">
        <a-card :bordered="false">
          <div
            class="table-operator"
            style="overflow:hidden;color: rgba(0, 0, 0, 0.85);"
          >
            <label
              class="listTitle first"
              style="display:block;width:100%;margin-bottom:20px"
              >{{ showRentOutType(this.rentOutType) }}</label
            >
            <Description term="审批发起人" class="w25">
              <label>{{ this.data.applyPerson }}</label>
            </Description>
            <Description term="申请日期" class="w25">
              <label>{{ this.data.applyDate }}</label>
            </Description>
            <Description term="违约退租类型" class="w25">
              <label>{{ this.data.detailTypeName }}</label>
            </Description>
            <Description term="是否退费" class="w25">
              <label>{{ this.data.refund }}</label>
            </Description>
            <Description term="实际离场日期" class="w25">
              <label>{{ this.data.actualDepartureDate }}</label>
            </Description>
            <Description term="房租计算结束日期" class="w25">
              <label>{{ this.data.rentEndDate }}</label>
            </Description>
            <Description term="外卖平台预计关闭日期" class="w25">
              <label>{{ this.data.takeOutExpCloseDate }}</label>
            </Description>
            <Description term="离场清洁费" class="w25">
              <label>{{ this.data.cleaningFee }}</label>
            </Description>
            <Description term="离场工程费" class="w25">
              <label>{{ this.data.engineeringFee }}</label>
            </Description>
            <Description term="场地图片" class="w100">
              <label>
                <viewer :images="this.data.pics" style="display:inline">
                  <img
                    v-for="(item, index) in this.data.pics"
                    :src="item"
                    :key="index"
                    style="height:50px;cursor:pointer;margin-right:10px;"
                  />
                </viewer>
              </label>
            </Description>
            <Description term="解约函" class="w50">
              <label>
                <viewer :images="this.data.cancelPics" style="display:inline">
                  <img
                    v-for="(item, index) in this.data.cancelPics"
                    :src="item"
                    :key="index"
                    style="height:50px;cursor:pointer;margin-right:10px;"
                  />
                </viewer>
              </label>
            </Description>
            <Description term="遗留物品通知函" class="w50">
              <label>
                <viewer :images="this.data.collectPics" style="display:inline">
                  <img
                    v-for="(item, index) in this.data.collectPics"
                    :src="item"
                    :key="index"
                    style="height:50px;cursor:pointer;margin-right:10px;"
                  />
                </viewer>
              </label>
            </Description>
            <Description term="终止协议" class="w50">
              <label>
                <viewer :images="this.data.abortPics" style="display:inline">
                  <img
                    v-for="(item, index) in this.data.abortPics"
                    :src="item"
                    :key="index"
                    style="height:50px;cursor:pointer;margin-right:10px;"
                  />
                </viewer>
              </label>
            </Description>
            <Description term="场地物品交接单" class="w50">
              <label>
                <viewer :images="this.data.handOverPics" style="display:inline">
                  <img
                    v-for="(item, index) in this.data.handOverPics"
                    :src="item"
                    :key="index"
                    style="height:50px;cursor:pointer;margin-right:10px;"
                  />
                </viewer>
              </label>
            </Description>
            <Description term="审批通过日期" class="w100">
              <label>{{ this.data.passTime }}</label>
            </Description>
            <Description term="备注" class="w100">
              <label>{{ this.data.remark }}</label>
            </Description>
          </div>
        </a-card>
      </a-card>
    </div>
    <!-- 期满退租、提前离场 -->
    <div v-if="this.rentOutType == 1 || this.rentOutType == 2">
      <a-card v-if="noTitleKey === 'baseDetail'">
        <a-card :bordered="false">
          <div
            class="table-operator"
            style="overflow:hidden;color: rgba(0, 0, 0, 0.85);"
          >
            <label
              class="listTitle first"
              style="display:block;width:100%;margin-bottom:20px"
              >{{ showRentOutType(this.rentOutType) }}</label
            >
            <Description term="审批发起人" class="w50">
              <label>{{ this.data.applyPerson }}</label>
            </Description>
            <Description term="申请日期" class="w50">
              <label>{{ this.data.applyDate }}</label>
            </Description>
            <Description term="预计离场日期" class="w50">
              <label>{{ this.data.expectedDepartureDate }}</label>
            </Description>
            <Description term="房租计算结束日期" class="w50">
              <label>{{ this.data.rentEndDate }}</label>
            </Description>
            <Description term="外卖平台预计关闭日期" class="w50">
              <label>{{ this.data.takeOutExpCloseDate }}</label>
            </Description>
            <Description term="场地图片" class="w100">
              <label>
                <viewer :images="this.data.pics" style="display:inline">
                  <img
                    v-for="(item, index) in this.data.pics"
                    :src="item"
                    :key="index"
                    style="height:50px;cursor:pointer;margin-right:10px;"
                  />
                </viewer>
              </label>
            </Description>
            <Description term="退款通知函" class="w50">
              <label>
                <viewer :images="this.data.notifyPics" style="display:inline">
                  <img
                    v-for="(item, index) in this.data.notifyPics"
                    :src="item"
                    :key="index"
                    style="height:50px;cursor:pointer;margin-right:10px;"
                  />
                </viewer>
              </label>
            </Description>
            <Description term="退租申请表" class="w50">
              <label>
                <viewer :images="this.data.applyPics" style="display:inline">
                  <img
                    v-for="(item, index) in this.data.applyPics"
                    :src="item"
                    :key="index"
                    style="height:50px;cursor:pointer;margin-right:10px;"
                  />
                </viewer>
              </label>
            </Description>
            <Description term="审批通过日期" class="w100">
              <label>{{ this.data.passTime }}</label>
            </Description>
            <Description term="备注" class="w100">
              <label>{{ this.data.remark }}</label>
            </Description>
          </div>
        </a-card>
      </a-card>
      <a-card v-if="noTitleKey === 'exitDetail'">
        <a-card :bordered="false">
          <div
            class="table-operator"
            style="overflow:hidden;color: rgba(0, 0, 0, 0.85);"
          >
            <label
              class="listTitle first"
              style="display:block;width:100%;margin-bottom:20px"
              >{{ showRentOutType(this.rentOutType) }}</label
            >
            <Description term="审批发起人" class="w30">
              <label>{{ this.data1.applyPerson }}</label>
            </Description>
            <Description term="登记日期" class="w30">
              <label>{{ this.data1.applyDate }}</label>
            </Description>
            <Description term="实际离场日期" class="w30">
              <label>{{ this.data1.actualDepartureDate }}</label>
            </Description>
            <Description term="房租计算结束日期" class="w30">
              <label>{{ this.data1.rentEndTime }}</label>
            </Description>
            <Description term="离场清洁费" class="w30">
              <label>{{ this.data1.cleaningFee }}</label>
            </Description>
            <Description term="离场工程费" class="w30">
              <label>{{ this.data1.engineeringFee }}</label>
            </Description>
            <Description term="其他附件" class="w100">
              <label>{{ this.data1.otherFiles }}</label>
            </Description>
            <Description term="场地图片" class="w100">
              <label>
                <viewer :images="this.data1.pics" style="display:inline">
                  <img
                    v-for="(item, index) in this.data1.pics"
                    :src="item"
                    :key="index"
                    style="height:50px;cursor:pointer;margin-right:10px;"
                  />
                </viewer>
              </label>
            </Description>
            <Description term="场地交接书" class="w100">
              <label>
                <viewer :images="this.data1.handOverPics" style="display:inline">
                  <img
                    v-for="(item, index) in this.data1.handOverPics"
                    :src="item"
                    :key="index"
                    style="height:50px;cursor:pointer;margin-right:10px;"
                  />
                </viewer>
              </label>
            </Description>
            <Description term="审批通过日期" class="w100">
              <label>{{ this.data1.passTime }}</label>
            </Description>
            <Description term="备注" class="w100">
              <label>{{ this.data1.remark }}</label>
            </Description>
          </div>
        </a-card>
      </a-card>
    </div>
  </div>
</template>

<script>
import begin from "@/assets/begin.png";
import close from "@/assets/close.png";
import warn from "@/assets/warn.png";
import moment from "moment";
import axios from "axios";
import DescriptionList from "@/components/DescriptionList";
const { Description } = DescriptionList;
import Bus from "@/bus";
  
export default {
  name: "terminateLeaseDetail",
  components: {
    DescriptionList,
    Description
  },
  data() {
    return {
      id: this.$route.query.id,
      rentOutId: this.$route.query.rentOutId,
      contractId: this.$route.query.contractId,
      tenantStatus: this.$route.query.tenantStatus,
      facilityName: this.$route.query.facilityName,
      kitchenBrand: this.$route.query.kitchenBrand,
      kitchenCode: this.$route.query.kitchenCode,
      storeName: this.$route.query.storeName,
      cityName: this.$route.query.cityName,
      contractCode: this.$route.query.contractCode,
      rentOutType: this.$route.query.rentOutType,
      reasonLiabilityName: null, // 退租责任方
      reasonCatName: null, // 退租原因
      reasonSubName: null, // 详细原因
      remark: null, // 备注
      begin,
      warn,
      close,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      tabListNoTitle: [
        {
          key: "baseDetail",
          tab: "退租信息"
        },
        {
          key: "exitDetail",
          tab: "离场信息"
        }
      ],
      noTitleKey: "baseDetail",
      data: [],
      data1: [],
      brandNameList1: [],
      terminateList: [
        { id: 1, name: "期满退租" },
        { id: 2, name: "提前退租" },
        { id: 3, name: "违约退租" }
      ],
      statusList: [
        { id: 1, name: "预订中" },
        { id: 2, name: "已预订" },
        { id: 3, name: "签约中" },
        { id: 4, name: "已签约" },
        { id: 5, name: "改造中" },
        { id: 6, name: "待入驻" },
        { id: 7, name: "已入驻" },
        { id: 8, name: "申请退租中" },
        { id: 9, name: "退租中" },
        { id: 10, name: "已退租" },
        { id: 11, name: "已离场" }
      ]
    };
  },
  created() {
    this.changeDetail();
    this.init();
    this.searchBrandList(0);
    Bus.$on("getTarget", target => {
      this.$router.push({
        path: "/store/terminateLeaseList"
      });
    });
  },
  computed: {
    // 厨房品牌
    showKitchenBrand(value) {
      return function(value) {
        let val = "";
        this.brandNameList1.forEach(item => {
          if (value == item.code) {
            val = item.optionText;
          }
        });
        return val;
      };
    },
    // 退租类型
    showRentOutType(value) {
      return function(value) {
        let val = "";
        this.terminateList.forEach(item => {
          if (value == item.id) {
            val = item.name;
          }
        });
        return val;
      };
    },
    // 客户状态
    showTenantStatus(value) {
      return function(value) {
        let val = "";
        this.statusList.forEach(item => {
          if (value == item.id) {
            val = item.name;
          }
        });
        return val;
      };
    }
  },
  methods: {
    init() {
      axios({
        url:   this.$baseUrl + "rentOut/query",
        method: "POST",
        withCredentials: true,
        params: {
          applicationId: this.id
        }
      }).then(res => {
        if (res.data.obj) {
          this.data = res.data.obj;
          this.reasonLiabilityName = res.data.obj.reasonLiabilityName;
          this.reasonCatName = res.data.obj.reasonCatName;
          this.reasonSubName = res.data.obj.reasonSubName;
          this.remark = res.data.obj.remark;
          this.data.actualDepartureDate = this.data.actualDepartureDate
            ? moment(this.data.actualDepartureDate).format("YYYY-MM-DD")
            : "";
          this.data.applyDate = this.data.applyDate
            ? moment(this.data.applyDate).format("YYYY-MM-DD")
            : "";
          this.data.expectedDepartureDate = this.data.expectedDepartureDate
            ? moment(this.data.expectedDepartureDate).format("YYYY-MM-DD")
            : "";
          this.data.passTime = this.data.passTime
            ? moment(this.data.passTime).format("YYYY-MM-DD")
            : "";
          this.data.rentEndDate = this.data.rentEndDate
            ? moment(this.data.rentEndDate).format("YYYY-MM-DD")
            : "";
          this.data.takeOutExpCloseDate = this.data.takeOutExpCloseDate
            ? moment(this.data.takeOutExpCloseDate).format("YYYY-MM-DD")
            : "";
        } else {
          this.data = [];
        }
      });
      axios({
        url:   this.$baseUrl + "departure/query",
        method: "GET",
        withCredentials: true,
        params: {
          rentOutId: this.rentOutId
        }
      }).then(res => {
        if (res.data.obj) {
          this.data1 = res.data.obj;
          this.data1.actualDepartureDate = this.data1.actualDepartureDate
            ? moment(this.data1.actualDepartureDate).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : "";
          this.data1.applyDate = this.data1.applyDate
            ? moment(this.data1.applyDate).format("YYYY-MM-DD HH:mm:ss")
            : "";
          this.data1.passTime = this.data1.passTime
            ? moment(this.data1.passTime).format("YYYY-MM-DD HH:mm:ss")
            : "";
          this.data1.rentEndTime = this.data1.rentEndTime
            ? moment(this.data1.rentEndTime).format("YYYY-MM-DD HH:mm:ss")
            : "";
        } else {
          this.data1 = [];
        }
      });
    },
    //合同详情
    contractDetail() {
      this.$router.push({
        path: "/role/contractList/contractDetail",
        query: { contractDetailsId: this.contractId }
      });
    },
    searchBrandList(val) {
      axios({
        url:   this.$baseUrl + "facilityBrand/selectByIds",
        method: "GET",
        params: {
          cityIds:
            JSON.stringify(val)
              .replace("[", "")
              .replace("]", "") || 0
        }
      }).then(res => {
        if (res.data.rows) {
          this.brandNameList1 = res.data.rows;
          res.data.rows.unshift({ id: 0, code: 0, optionText: "全部" });
        }
      });
    },
    changeDetail() {
      //根据退租状态的不同，改变标题的显示
      if (this.rentOutType == 1 || this.rentOutType == 2) {
        this.tabListNoTitle = [
          {
            key: "baseDetail",
            tab: "退租信息"
          },
          {
            key: "exitDetail",
            tab: "离场信息"
          }
        ];
      }
      if (this.rentOutType == 3) {
        this.tabListNoTitle = [
          {
            key: "baseDetail",
            tab: "退租信息"
          }
        ];
      }
    },
    // 退租信息、离场信息的切换
    onTabChange(key, type) {
      this[type] = key;
    },
    changeImgbg() {
      //   if (
      //     this.dataquery.storeStatusShow == "施工中" ||
      //     this.dataquery.storeStatusShow == "已签约"
      //   ) {
      //     return "abuilding";
      //   } else if (this.dataquery.storeStatusShow == "开业中") {
      return "open";
      //   } else if (
      //     this.dataquery.storeStatusShow == "停业中" ||
      //     this.dataquery.storeStatusShow == "已关店"
      //   ) {
      //     return "close";
      //   } else {
      //     return "close";
      //   }
    }
  }
};
</script>

<style scoped>
.open {
  font-size: 12px;
}
.table-operator {
  margin-top: -24px;
  margin-bottom: 18px;
}
.table-operator button {
  margin-left: 8px;
  margin-right: 0px;
}
.table-operatorTitle {
  margin-bottom: 18px;
  width: 100%;
  overflow: hidden;
}
.marginB24 {
  margin-bottom: 24px;
}
.height120 .ant-col-md-8 {
  height: 120px;
}
.first {
  font-weight: bolder;
}
.two {
  display: inline-block;
  width: 27%;
}
.header-img {
  position: absolute;
  top: 50px;
  right: 0px;
  height: 200px;
  width: 200px;
}
.button {
  position: absolute;
  top: -10px;
  right: 0px;
}
.ant-carousel >>> .slick-dots {
  height: auto;
}
.ant-carousel >>> .slick-slide img {
  display: block;
  margin: auto;
  max-width: 80%;
}
.ant-carousel >>> .slick-thumb {
  bottom: -80px;
}
.ant-carousel >>> .slick-thumb li {
  width: 40px;
  height: 40px;
}
.ant-carousel >>> .slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}
.ant-carousel >>> .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
.fee > .ant-col-md-8 {
  width: 25%;
}
.w25 {
  width: 25%;
  float: left;
  line-height: 36px;
}
.w30 {
  width: 30%;
  float: left;
  line-height: 36px;
}
.w50 {
  width: 50%;
  float: left;
  line-height: 36px;
}
.w100 {
  width: 100%;
  float: left;
  line-height: 36px;
}
</style>
